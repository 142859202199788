@media only screen and (min-width: 1026px) {

    nav {
        background: var(--red);
        box-shadow: var(--light-shadow);
    }

    .nav-center {
        max-width: 1170px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0rem;
    }

    .flex {
        display: flex;
    }

    .forty {
        width: 40%;
    }
    
    .sixty {
        width: 60%;
    }

    .logo {
        height: 70px;
        margin-left: 7px;
    }    

    .box {
        width: calc(100% - 30px);
        border-width: 4px;
        border-radius: 10px;
        border-color: var(--black);
        border-style: solid;
        margin-top: 40px;
        margin-left: 20px;
        margin-bottom: 40px;
        margin-right: 20px;
        padding-bottom: 10px;
        box-shadow: 5px 5px 10px #888888;
    }
    
    .seventy {
        width:70%;
    }
    
    .thirty {
        width:30%;
    }

    .photo-B4 {
        width:120%;
    }

    .nav-header {
        padding: 0;
    }

    .nav-toggle {
        display: none;
    }

    .links-container {
        height: auto !important;
    }

    .links {
        display: flex;
    }

    .links a {
        padding: 0px 10px;
        font-size: 20px;
        font-weight: 600;
    }

    .links a:hover {
        background: transparent;
        color: var(--black);
        transition: var(--transition);
    }

    .title {
        font-size: 32px;
    }

    .center {
        text-align: center;
        justify-content: center;
    }

    .max-width {
        max-width: 1170px;
        margin: 0 auto;
    }

    .title-block1 {
        color: var(--red);
        margin-top: 15px;
        font-size: 40px;
        font-weight: 500;
    }

    .text-block1 {
        margin-top: 15px;
        font-size: 22px;
    }

    .size24 {
        font-size: 20px;
    }

    .footer {
        margin-top:20px;
        height: 60px;
        background-color: var(--red);
    }

    .text-footer {
        color: var(--white);
        font-size: 20px;
        padding-top: 22px;
        font-weight: 100;
    }

    .warning {
        border-radius: 5px;
        border-width: 4px;;
        border-style: solid;
        border-color: var(--red);
        margin-top: 10px;
        margin-left:200px;
        width:460px;
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 20px;
        padding:7px;
    }

    .b1 {
        margin-left: 160px;
        margin-top: 35px;
    }

}