@media only screen and (min-width: 481px) and (max-width:1025px) {

    /* start Navbar.js style */

    .title {
        font-size: 48px;
        font-family: var(--font-family);
    }

    .nav-toggle {
        color: var(--black);
    }

    .links a {
        margin-left: 20px;
    }

    .links li a:hover {
        color: var(--blue);
        transition: var(--transition);
    }
}